
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Property, getProperties } from '../services/property-service';
import { Tenant, getTenants } from '../services/tenant-service';
import { getUserProfile } from '../services/user-service';
import { useApi } from '../api/Api';

interface User {
    id: string;
    email: string;
    name?: string;
}

interface AuthContextType {
    user: User | null;
    loading: boolean;
    isAuthenticated: boolean;
    properties: Property[];
    tenants: Tenant[];
    setProperties: (properties: Property[]) => void;
    setTenants: (tenants: Tenant[]) => void;
    logout: () => Promise<void>;
    refreshUserData: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const api = useApi();

    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [properties, setProperties] = useState<Property[]>([]);
    const [tenants, setTenants] = useState<Tenant[]>([]);

    const fetchAllData = async () => {
        try {
            setLoading(true);
            const userProfile = await getUserProfile();
            if (!userProfile) {
                throw new Error('No user profile found');
            }

            setUser(userProfile);
            setIsAuthenticated(true);

            const [propertiesData, tenantsData] = await Promise.all([
                getProperties(api),
                getTenants(api)
            ]);

            setProperties(propertiesData);
            setTenants(tenantsData);
        } catch (error) {
            setIsAuthenticated(false);
            setUser(null);
            navigate('/user/signin');
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     fetchAllData();
    // }, []);

    const logout = async () => {
        try {
            // await api.post('/auth/logout');

            setUser(null);
            setIsAuthenticated(false);
            setProperties([]);
            setTenants([]);
            navigate('/user/signin');
        } catch (error) {
        }
    };

    const refreshUserData = async () => {
        await fetchAllData();
    };

    const value = {
        user,
        loading,
        isAuthenticated,
        properties,
        tenants,
        setProperties,
        setTenants,
        logout,
        refreshUserData
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        // throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}