import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Container,
    Alert,
    CircularProgress,
    CssBaseline,
    Avatar,
} from '@mui/material';
import { styled } from '@mui/system';
import NavBar from './NavBar';
import { Mail } from '@mui/icons-material';
import { useApi } from '../../api/Api';
import { NavigateToHome } from '../../utils/NavLinks';
import emailjs from '@emailjs/browser';

const FormContainer = styled(Container)({
    maxWidth: '500px',
    backgroundColor: '#ffffff',
    padding: '1.5rem',
    borderRadius: '6px',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)'
});

const FormField = styled(TextField)({
    marginBottom: '1rem',
    width: '100%',
    '& .MuiInputBase-input': {
        fontSize: '0.875rem',
    },
});

const SubmitButton = styled(Button)({
    backgroundColor: '#1976d2',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#115293',
    },
    width: '100%',
    padding: '0.75rem',
    fontSize: '0.875rem'
});

interface FormData {
    name: string;
    email: string;
    message: string;
}

interface FormErrors {
    email: string;
    message: string;
}

const ContactForm = ({ mt }: { mt: number }) => {
    const [formData, setFormData] = useState<FormData>({ name: '', email: '', message: '' });
    const [errors, setErrors] = useState<FormErrors>({ email: '', message: '' });
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const api = useApi();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (errors[e.target.name as keyof FormErrors]) {
            setErrors({ ...errors, [e.target.name]: '' });
        }
        setSuccessMessage('');
        setErrorMessage('');
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrors({ email: '', message: '' });
        setSuccessMessage('');
        setErrorMessage('');

        if (!validateEmail(formData.email)) {
            setErrors((prev) => ({ ...prev, email: 'Please enter a valid email address.' }));
            return;
        }
        if (!formData.message) {
            setErrors((prev) => ({ ...prev, message: 'Message cannot be empty.' }));
            return;
        }

        setLoading(true);

        try {
            const templateParams = {
                from_name: formData.name,
                from_email: formData.email,
                to_email: process.env.REACT_APP_TO_EMAIL,
                message: formData.message,
                reply_to: formData.email,
                subject: 'New Contact Form Submission'
            };
            await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                templateParams,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            );

            setSuccessMessage('Thank you for reaching out! We will get back to you soon.');
            setFormData({ name: '', email: '', message: '' });
        } catch (error) {
            setErrorMessage('Failed to send message. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <NavBar />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: mt,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 3,
                        borderRadius: 3,
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                        backgroundColor: '#f5f5f5',
                        maxWidth: 1000,
                        width: '100%',
                    }}
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <Mail />
                    </Avatar>
                    <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
                        Contact Us
                    </Typography>
                    {successMessage && <Alert severity="success" sx={{ width: '100%', mb: 2 }}>{successMessage}</Alert>}
                    {errorMessage && <Alert severity="error" sx={{ width: '100%', mb: 2 }}>{errorMessage}</Alert>}

                    <FormField
                        label="Full Name"
                        variant="outlined"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />

                    <FormField
                        label="Email Address"
                        variant="outlined"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        required
                    />

                    <FormField
                        label="Message"
                        variant="outlined"
                        name="message"
                        multiline
                        rows={4}
                        value={formData.message}
                        onChange={handleChange}
                        error={Boolean(errors.message)}
                        helperText={errors.message}
                        required
                    />

                    <SubmitButton type="submit" variant="contained" disabled={loading} startIcon={loading ? null : <Mail />}>
                        {loading ? <CircularProgress size={20} color="inherit" /> : 'Send Message'}
                    </SubmitButton>
                    <NavigateToHome />
                </Box>
            </Container>
        </>
    );
};

export default ContactForm;