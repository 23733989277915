import React, { useEffect, useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    Button
} from '@mui/material';
import {
    AccountCircle,
    Menu as MenuIcon,
    Home,
    Dashboard,
    MapsHomeWorkSharp,
    Person,
    Mail,
    AssignmentTurnedIn,
    Logout,
    Login,
    PersonAdd
} from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getUserProfile } from '../../services/user-service';

const SidebarNavBar = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [authenticatedUser, setAuthenticatedUser] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const data = await getUserProfile();
                setAuthenticatedUser(!!data);
            } catch {
                setAuthenticatedUser(false);
            }
        };
        checkAuth();
    }, [navigate]);

    const toggleDrawer = (open: boolean) => () => setSidebarOpen(open);
    const handleMenuClose = () => setAnchorEl(null);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/user/signin');
    };

    const handleLoginClick = () => navigate('/user/signin');
    const handleRegisterClick = () => navigate('/user/register');

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id="primary-search-account-menu"
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem component={RouterLink} to="/user/profile">Profile</MenuItem>
            <MenuItem component={RouterLink} to="/user/account">My account</MenuItem>
        </Menu>
    );

    const drawerContent = (
        <Box
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            sx={{ width: 250 }}
        >
            <List>
                <ListItem component={RouterLink} to="/home">
                    <ListItemIcon><Home /></ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                {authenticatedUser && (
                    <>
                        <ListItem component={RouterLink} to="/dashboard">
                            <ListItemIcon><Dashboard /></ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                        <ListItem component={RouterLink} to="/property/list">
                            <ListItemIcon><MapsHomeWorkSharp /></ListItemIcon>
                            <ListItemText primary="Properties" />
                        </ListItem>
                        <ListItem component={RouterLink} to="/tenant/list">
                            <ListItemIcon><Person /></ListItemIcon>
                            <ListItemText primary="Tenants" />
                        </ListItem>
                        <ListItem component={RouterLink} to="/lease/list">
                            <ListItemIcon><AssignmentTurnedIn /></ListItemIcon>
                            <ListItemText primary="Leases" />
                        </ListItem>
                        <ListItem component={RouterLink} to="/user/profile">
                            <ListItemIcon><AccountCircle /></ListItemIcon>
                            <ListItemText primary="Account" />
                        </ListItem>
                    </>
                )}
                <ListItem component={RouterLink} to="/contact">
                    <ListItemIcon><Mail /></ListItemIcon>
                    <ListItemText primary="Contact Us" />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <AppBar position="fixed" color="primary">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            flexGrow: 1,
                            fontSize: '2rem',
                            fontWeight: 'bold',
                            color: '#fff',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            letterSpacing: '0.15em',
                            fontFamily: `'Montserrat', sans-serif`,
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        Tenant Sync
                    </Typography>
                </Toolbar>
            </AppBar>

            <Drawer
                anchor="left"
                open={sidebarOpen}
                onClose={toggleDrawer(false)}
            >
                {drawerContent}
            </Drawer>

            {renderMenu}
        </div>
    );
};

export default SidebarNavBar;