import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DomainIcon from '@mui/icons-material/Domain';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from './Footer';
import NavBar from './NavBar';
import { HomeBoxStyle } from '../../utils/Styles';
import ContactForm from './ContactForm';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const theme = createTheme();

const Home = () => {
    const [showContactForm, setShowContactForm] = useState(false);

    const handleToggleContactForm = () => {
        setShowContactForm(prev => !prev);
    };

    return (
        <>
            <NavBar />
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        backgroundColor: '#f7f8fa',
                        color: '#333',
                    }}
                >
                    <CssBaseline />
                    <Container component="main" maxWidth="md" sx={{ flexGrow: 1 }}>
                        <Box sx={HomeBoxStyle}>
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <HomeIcon />
                            </Avatar>
                            <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Welcome to Tenant-Sync
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
                                Streamline your property management with Tenant-Sync – the ultimate platform for property, lease, and rent tracking, all in one place.
                            </Typography>

                            <Grid container spacing={3} sx={{ mt: 1, mb: 6 }}>
                                <Grid item xs={12} sm={6}>
                                    <FeatureBox
                                        icon={<DomainIcon color="primary" />}
                                        title="Property Management"
                                        description="Easily manage your property portfolio, track details, and monitor occupancy."
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FeatureBox
                                        icon={<AssignmentIcon color="primary" />}
                                        title="Lease Management"
                                        description="Efficiently manage lease agreements, renewals, and document uploads."
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FeatureBox
                                        icon={<MonetizationOnIcon color="primary" />}
                                        title="Rent Tracking"
                                        description="Track rent payments, automate reminders, and monitor tenant balances."
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FeatureBox
                                        icon={<PersonIcon color="primary" />}
                                        title="Tenant Management"
                                        description="Keep detailed records of tenants, including contact and payment information."
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FeatureBox
                                        icon={<BuildIcon color="primary" />}
                                        title="Maintenance Requests"
                                        description="Organize and track maintenance requests to ensure timely repairs."
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FeatureBox
                                        icon={<AddShoppingCartIcon color="primary" />}
                                        title="Expense Tracking"
                                        description="Record and categorize expenses to monitor property profitability."
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FeatureBox
                                        icon={<DashboardIcon color="primary" />}
                                        title="Insight Dashboard"
                                        description="Gain insights into your properties with an interactive dashboard."
                                    />
                                </Grid>
                                <Grid item xs={12} textAlign='center'>
                                    <Button
                                        variant="contained"
                                        onClick={handleToggleContactForm}
                                        sx={{ mb: 2, textAlign:'center', bgcolor: 'success.main'}}
                                        startIcon={showContactForm ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        endIcon={<ContactMailIcon />}
                                    >
                                        {showContactForm ? 'Hide Contact Form' : 'Contact us'}
                                    </Button>
                                    <Collapse in={showContactForm}>
                                        <ContactForm mt={4}/>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                    <Footer />
                </Box>
            </ThemeProvider>
        </>
    );
};

const FeatureBox = ({ icon, title, description }: any) => (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        p: 2,
        boxShadow: 1,
        borderRadius: 2,
        backgroundColor: 'background.paper',
        '&:hover': {
            boxShadow: 3,
            transition: 'box-shadow 0.3s',
        },
    }}>
        <Avatar sx={{ mr: 2, bgcolor: 'transparent' }}>{icon}</Avatar>
        <Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{title}</Typography>
            <Typography variant="body2" color="text.secondary">{description}</Typography>
        </Box>
    </Box>
);

export default Home;