import { jwtDecode, JwtPayload } from "jwt-decode";

interface DecodedToken {
    sub: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role: string;
}

export const isAuthenticated = () : boolean => {
    try {
        const decodedToken: JwtPayload = jwtDecode(getToken());
        const currentDate: number = Math.floor(Date.now() / 1000);
        return decodedToken.exp !== undefined ? decodedToken.exp > currentDate : false;
    } catch (error) {
        return false;
    }
}

export const setToken = (token: string, callback?: Function) => {
    localStorage.setItem('token', token);
    if (callback) {
        callback();
    }
};

export const getToken = (): string => {
    const token: string | null = localStorage.getItem('token');
    return token !== null ? token : "";
};

export const removeToken = () => {
    console.log('removing token')
    localStorage.removeItem('token');
};

