import { authConfig } from "../api/Api";
import { getToken, isAuthenticated } from "../utils/JwtUtil";
import { jwtDecode } from "jwt-decode";

export interface LoginRequest {
    email: string;
    password: string;
}

export interface RegisterRequest {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    password: string;
    role: string;
    email: string;
}

export const login = async (api: any, loginRequest: LoginRequest) => {
    const response = await api.post('/auth/login', loginRequest);
    return response?.data;
};

export const register = async (api: any, registerRequest: RegisterRequest) => {
    const response = await api.post('/auth/register', registerRequest);
    return response?.data;
};

export const getUserProfile = async (): Promise<any> => {
    return isAuthenticated() ? jwtDecode(getToken()) : null;
};

export const updateUserProfile = async (api: any, newData: any) => {
    console.log('New data == ', newData);
    const response = await api.put('/users', newData, authConfig);
    return response?.data;
};