import { authConfig } from "../api/Api";

export interface Property {
    id: string;
    name: string;
    address: string;
    type: string;
    description: string;
    numberOfUnits: string;
    surface: string;
    bedrooms: string;
    bathrooms: string;
    rentAmount: string;
    facilities: string[];
}

export const getProperties = async (api: any): Promise<Property[]> => {
    const response = await api.get('/properties', authConfig);
    return response?.data;
};

export const createProperty = async (api: any, property: any): Promise<Property> => {
    const response = await api.post('/properties', property, authConfig);
    return response?.data;
};

export const updateProperty = async (api: any, property: Property): Promise<Property> => {
    const response = await api.put(`/properties/${property.id}`, JSON.stringify(property), authConfig);
    return response?.data;
};

export const deleteProperty = async (api: any, id: string | undefined): Promise<void> => {
    await api.delete(`/properties/${id}`, authConfig);
};

export const getPropertyById = async (api: any, id: string): Promise<Property> => {
    const response = await api.get(`/properties/${id}`, authConfig);
    return response?.data;
}
