import { authConfig } from "../api/Api";


export interface Tenant {
    id: string;
    name: string;
    email: string;
    phoneNumber: string;
}

export const getTenants = async (api: any): Promise<Tenant[]> => {
    const response = await api.get('/tenants', authConfig);
    return response?.data;
};

export const createTenant = async (api: any, tenant: any): Promise<Tenant> => {
    const response = await api.post('/tenants', tenant, authConfig);
    return response?.data;
};

export const updateTenant = async (api: any, tenant: Tenant): Promise<Tenant> => {
    const response = await api.put(`/tenants/${tenant.id}`, JSON.stringify(tenant), authConfig);
    return response?.data;
};

export const deleteTenant = async (api: any, id: string | undefined): Promise<void> => {
    await api.delete(`/tenants/${id}`, authConfig);
};

export const getTenantById = async (api: any, id: string): Promise<Tenant> => {
    const response = await api.get(`/tenants/${id}`, authConfig);
    return response?.data;
}
