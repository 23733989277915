import { AccountCircle, AddHomeSharp, AssignmentTurnedIn, Dashboard, Home, People } from "@mui/icons-material";
import { Grid, Link } from "@mui/material";

export const NavigateToDahsboard = () => {
    return (
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
                <Link
                    href="/dashboard"
                    variant="body2"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        color: 'primary.main',
                        '&:hover': {
                            textDecoration: 'underline',
                            color: 'secondary.main',
                        },
                        '&:focus': {
                            outline: '2px solid #ff9800'
                        }
                    }}
                >
                    <Dashboard sx={{ mr: 1 }} />
                    Go to Dashboard
                </Link>
            </Grid>
        </Grid>
    );
}

export const NavigateToSignIn = () => {
    return (
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
                <Link
                    href="/user/signin"
                    variant="body2"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        color: 'primary.main',
                        '&:hover': {
                            textDecoration: 'underline',
                            color: 'secondary.main',
                        },
                        '&:focus': {
                            outline: '2px solid #ff9800'
                        }
                    }}
                >
                    <AccountCircle sx={{ mr: 1 }} />
                    Already have an account? Sign in
                </Link>
            </Grid>
        </Grid>
    );
}

export const NavigateToSignUp = () => {
    return (
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
                <Link
                    href="/user/register"
                    variant="body2"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        color: 'primary.main',
                        '&:hover': {
                            textDecoration: 'underline',
                            color: 'secondary.main',
                        },
                        '&:focus': {
                            outline: '2px solid #ff9800'
                        },
                    }}
                >
                    <AccountCircle sx={{ mr: 1 }} />
                    Don't have an account? Register
                </Link>
            </Grid>
        </Grid>
    );
}

export const NavigateToPrpertyList = () => {
    return (
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
                <Link
                    href="/property/list"
                    variant="body2"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        color: 'primary.main',
                        '&:hover': {
                            textDecoration: 'underline',
                            color: 'secondary.main',
                        },
                        '&:focus': {
                            outline: '2px solid #ff9800'
                        }
                    }}
                >
                    <AddHomeSharp sx={{ mr: 1 }} />
                    Return to property list
                </Link>
            </Grid>
        </Grid>
    );
}

export const NavigateToTenantList = () => {
    return (
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
                <Link
                    href="/tenant/list"
                    variant="body2"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        color: 'primary.main',
                        '&:hover': {
                            textDecoration: 'underline',
                            color: 'secondary.main',
                        },
                        '&:focus': {
                            outline: '2px solid #ff9800'
                        }
                    }}
                >
                    <People sx={{ mr: 1 }} />
                    Return to tenant list
                </Link>
            </Grid>
        </Grid>
    );
}

export const NavigateToProfile = () => {
    return (
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
                <Link
                    href="/user/profile"
                    variant="body2"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        color: 'primary.main',
                        '&:hover': {
                            textDecoration: 'underline',
                            color: 'secondary.main',
                        },
                        '&:focus': {
                            outline: '2px solid #ff9800'
                        }
                    }}
                >
                    <AccountCircle sx={{ mr: 1 }} />
                    Go to profile
                </Link>
            </Grid>
        </Grid>
    );
}


export const NavigateToLeases = () => {
    return (
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
                <Link
                    href="/lease/list"
                    variant="body2"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        color: 'primary.main',
                        '&:hover': {
                            textDecoration: 'underline',
                            color: 'secondary.main',
                        },
                        '&:focus': {
                            outline: '2px solid #ff9800'
                        }
                    }}
                >
                    <AssignmentTurnedIn sx={{ mr: 1 }} />
                    Go to Lease list
                </Link>
            </Grid>
        </Grid>
    );
}

export const NavigateToHome = () => {
    return (
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
                <Link
                    href="/home"
                    variant="body2"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        color: 'primary.main',
                        '&:hover': {
                            textDecoration: 'underline',
                            color: 'secondary.main',
                        },
                        '&:focus': {
                            outline: '2px solid #ff9800'
                        }
                    }}
                >
                    <Home sx={{ mr: 1 }} />
                    Go to home
                </Link>
            </Grid>
        </Grid>
    );
}