import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from "./components/pages/Home";
import ContactForm from './components/pages/ContactForm';
import { AuthProvider } from './utils/AuthContext';
import emailjs from '@emailjs/browser';

const App = () => {

  emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY!);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='contact' element={<ContactForm mt={12}/>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;