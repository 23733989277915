import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getToken, setToken, removeToken } from '../utils/JwtUtil';  // Modify this util to support setting and removing tokens

// Create a base axios instance
const api = axios.create({
    baseURL: 'http://localhost:8080/api/v1'
});

// Auth config for JSON requests
export const authConfig = {
    headers: {
        'Authorization': `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
    }
};

// Auth config for file uploads (multipart)
export const uploadFileConfig = {
    headers: {
        'Authorization': `Bearer ${getToken()}`,
        'Content-Type': 'multipart/form-data'
    }
};

// Function to request a new access token using the refresh token
const refreshAccessToken = async (): Promise<string | null> => {
    try {
        // Send request to refresh the token
        const response = await axios.post('http://localhost:8080/api/v1/auth/refresh-token', null, { 
            withCredentials: true  // Ensure cookies are sent with the request
        });
        
        const { token } = response.data;

        if (token) {
            console.log('New access token received:', token);
            setToken(token);  // Store the new token in localStorage or sessionStorage
            return token;
        } else {
            console.error('No token found in refresh response');
            return null;
        }
    } catch (error : any) {
        // Check if it's an auth issue or a network error
        if (error?.response?.status === 401) {
            console.error('Refresh token has expired or is invalid, redirecting to login.');
        } else {
            console.error('Unable to refresh access token due to network or other issues:', error);
        }
        return null;
    }
};

// Axios instance with interceptor for refreshing tokens
export const useApi = () => {
    const navigate = useNavigate();

    const api = axios.create({
        baseURL: 'http://localhost:8080/api/v1'
    });

    // Interceptor to handle token expiration
    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            // Check if there's no token, meaning the user is likely not authenticated
            const token = getToken();
            if (!token) {
                navigate('/user/signin');
                return Promise.reject(error);
            }

            // If error is due to authorization
            // if (error.response.status === 401 || error.response.status === 403) {
            //     console.log("Access token expired or unauthorized. Redirecting to sign-in page.");
            //     navigate('/user/signin');
            // }

            return Promise.reject(error);
        }
    );

    // Set the token in the Authorization header for every request
    api.interceptors.request.use(
        (config) => {
            const token = getToken();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    return api;
};