import { Box, Typography, Container, Grid, Link, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
    return (
        <Box
            sx={{
                bgcolor: 'primary.main',
                color: 'white',
                py: 4,
                mt: 'auto'
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Tenant-Sync
                        </Typography>
                        <Typography variant="body2">
                            Your trusted partner in property management software solutions.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography variant="body2" mb={2}>
                            <Link
                                component={RouterLink}
                                to="/contact"
                                sx={{ color: 'inherit', textDecoration: 'none'}}
                            >
                                Contact Form
                            </Link>
                        </Typography>
                        

                        <Typography variant="body2">
                            Email: info@tenant-sync.com
                        </Typography>
                        <Typography variant="body2">
                            Address: Dublin, Ireland
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Follow Us
                        </Typography>
                        <Box>
                            <IconButton color="inherit" href="https://www.linkedin.com/company/tenant-sync/about/" target="_blank">
                                <LinkedInIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>

                <Box textAlign="center" sx={{ mt: 4 }}>
                    <Typography variant="body2" color="inherit">
                        © {new Date().getFullYear()} Propery Sync. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;